import { render, staticRenderFns } from "./public404.vue?vue&type=template&id=78c0bcae&scoped=true&"
import script from "./public404.vue?vue&type=script&lang=js&"
export * from "./public404.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c0bcae",
  null
  
)

export default component.exports