<template>
  <page404></page404>
</template>

<script>
export default {
  components: {
    page404: () => import('modules/base/components/404.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>